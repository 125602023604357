import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

import IPersonInfo from "src/shared/interfaces/general/person";

/**Estrutura esperada das props do contexto
 * @param personInfo objeto contendo as informações da compra do usuário
 * @param setPersonTypes setter do personInfo
 */
interface IPersonData {
  personInfo: IPersonInfo;
  setPersonInfo: Dispatch<SetStateAction<IPersonInfo>>;
}

/**Estrutura esperada do provider do contexto */
interface IPersonProviderProps {
  children: ReactNode;
}

export const PersonContext = createContext({} as IPersonData);

export function PersonProvider({ children }: IPersonProviderProps) {
  /**Variável que mantém as informações do usuário */
  const [personInfo, setPersonInfo] = useState<IPersonInfo>(() => {
    const params = new URL(window.location.href).searchParams;

    const utm = {
      utmSource: params.get("utm_source") ? params.get("utm_source") : null,
      utmMedium: params.get("utm_medium") ? params.get("utm_medium") : null,
      utmCampaign: params.get("utm_campaign")
        ? params.get("utm_campaign")
        : null,
      utmContent: params.get("utm_content") ? params.get("utm_content") : null,
      utmTerm: params.get("utm_term") ? params.get("utm_term") : null,
    };

    if (params.get("igti_checkout_personal_info")) {
      const obj = JSON.parse(params.get("igti_checkout_personal_info")!);

      if (!("cpf" in obj)) {
        obj["cpf"] = "";
      }

      return {
        ...obj,
        ...utm,
        birthDate: null,
        b2bCompany: obj?.b2bCompany || "",
        cep: "",
        state: "",
        city: "",
        neighborhood: "",
        street: "",
        number: "",
        complement: "",
        termsAccepted: false,
      };
    } else
      return {
        id: "",
        name: "",
        cpf: "",
        b2bCompany: "",
        ...utm,
        birthDate: null,
        email: "",
        phone: "",
        cep: "",
        state: "",
        city: "",
        neighborhood: "",
        street: "",
        number: "",
        complement: "",
        termsAccepted: false,
      };
  });

  return (
    <PersonContext.Provider
      value={{
        personInfo,
        setPersonInfo,
      }}
    >
      {children}
    </PersonContext.Provider>
  );
}

/**Hook utilizado para acessar as informações do contexto */
export function usePerson(): IPersonData {
  const context = useContext(PersonContext);

  if (!context) {
    throw new Error("usePerson must be used within a PersonProvider");
  }

  return context;
}
