import {
	useCallback,
	useEffect,
	useState,
	type FC,
	type FormEvent,
} from "react";

import ArrowForward from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import CustomLoader from "src/shared/components/general/Loader";

import { baseAPI, baseHeaders } from "src/shared/config/axios";

import { usePayment } from "src/shared/contexts/Payment";
import { usePerson } from "src/shared/contexts/Person";
import { useProduct } from "src/shared/contexts/Product";
import { useToast } from "src/shared/contexts/Toast";

import type IAccordion from "src/shared/interfaces/accordions";
import type IProduct from "src/shared/interfaces/general/product";
import type { IProductType } from "src/shared/interfaces/general/product";

import CustomAccordion from "src/shared/components/general/Accordion";

import { CompaniesDiv, Container, StyledFormControlLabel } from "./style";

const ItemInfoAccordion: FC<IAccordion> = ({
	id,
	disabled,
	expanded,
	accordions,
	setAccordions,
	setActiveStep,
}) => {
	/**Variáveis que mantêm as informações do usuário */
	const {
		personInfo: { id: personID, b2bCompany },
	} = usePerson();
	/**Variáveis que mantém os tipos de produto possíveis, e o(s) objeto(s) do(s) produto(s) sendo adquirido(s) pelo usuário */
	const { productTypes, products, acquiredProducts, setAcquiredProducts } =
		useProduct();
	/**Variável que mantém as informações de pagamento do usuário */
	const { setPaymentInfo } = usePayment();

	/**Função que realiza a inserção de toasts informativos */
	const { addToast } = useToast();

	/**Variável que mantém a tab atualmente ativa */
	const [currentTab, setCurrentTab] = useState(0);
	/**Variável que mantém o produto atualmente selecionado pelo usuário */
	const [currentSelectedProduct, setCurrentSelectedProduct] =
		useState<IProduct>({} as IProduct);

	/**Flag que controla o status de carregamento da requisição de submissão do accordion */
	const [isLoading, setIsLoading] = useState(false);

	/**Flag que controla a renderização do componente de Tabs*/
	const [shouldShowTabs, setShouldShowTabs] = useState(true);

	/**Função responsável por validar todos os campos e submeter o accordion */
	const handleSubmitAccordion = useCallback(
		async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			setIsLoading(true);

			const lead = sessionStorage.getItem("@checkout_lead@");

			if (!lead) {
				addToast({
					type: "info",
					title: "Informações incompletas",
					description:
						"Insira e confirme suas informações pessoais e de endereço para continuar com a compra",
				});

				return;
			}

			try {
				const {
					data: { success, response },
				} = await baseAPI.put(
					`/leads/${lead}/`,
					{
						product_list: acquiredProducts.map(({ crypted_id }) => crypted_id),
						product_name: acquiredProducts[0].nome,
					},
					{ headers: baseHeaders() },
				);

				// caso o lead seja b2b, um novo valor é atruído ao produto
				if (response.updated_product) {
					setAcquiredProducts((prevProducts) =>
						prevProducts.map((product) =>
							product.crypted_id === response.updated_product.crypted_id
								? {
										...product,
										valor: response.updated_product.valor,
										valor_descontado: response.updated_product.valor_descontado,
										valor_descontado_original:
											response.updated_product.valor_descontado_original,
									}
								: product,
						),
					);
				}

				if (success) {
					setAccordions((prev) =>
						prev.map((acc) =>
							acc.id === id
								? {
										...acc,
										group: 1,
										completed: true,
										disabled: true,
										completedInfo: {
											...acc.completedInfo,
											button: "Alterar",
											content: acquiredProducts.map(
												({ nome, nome_exibicao }) => `${nome_exibicao ?? nome}`,
											),
										},
									}
								: prev.find(
											({ id: prev_id, disabled }) =>
												prev_id !== id && !disabled,
										)
									? { ...acc }
									: acc.id === id + 1
										? { ...acc, disabled: false, expanded: true }
										: { ...acc },
						),
					);

					addToast({
						type: "success",
						title: "Informações confirmadas",
						description:
							"As informações do produto foram validadas e registradas com sucesso",
					});
				} else setAcquiredProducts([]);
			} catch {
				setAccordions((prev) =>
					prev.map((acc) =>
						acc.id === id ? { ...acc, completed: false } : { ...acc },
					),
				);

				setAcquiredProducts([]);

				addToast({
					type: "error",
					title: "Erro ao confirmar produtos",
					description:
						"Ocorreu um erro ao tentar confirmar a aquisição dos produtos. Recarregue a página e tente novamente",
				});
			} finally {
				setIsLoading(false);
			}
		},
		[id, acquiredProducts, setAcquiredProducts, setAccordions, addToast],
	);

	/**Efeito que verifica se o usuário está tentando adquirir um produto prime. Se sim, checa a nota obtida pelo mesmo no teste respectivo, para verificar sua eligibilidade */
	useEffect(() => {
		const checkUserEligibility = async () => {
			/**Recupera o id do tipo de produto "MBA Prime" */
			const { crypted_id: productTypeID } =
				(productTypes.find(
					({ tipo_produto }) => tipo_produto === "MBA Prime",
				) as IProductType) ?? {};

			/**Verifica se algum produto adquirido pertence ao tipo de produto "MBA Prime" */
			const foundProducts = acquiredProducts.filter(
				({ crypted_product_type_id }) =>
					crypted_product_type_id === productTypeID,
			);

			/**Caso sejam encontrados produtos desta categoria, verifica se o usuário foi aprovado nas provas necessárias */
			if (foundProducts.length > 0) {
				try {
					const {
						data: {
							response: { mindsight },
						},
					} = await baseAPI.get(`/mindsight/${personID}/`, {
						headers: baseHeaders(),
					});

					if (mindsight && typeof mindsight !== "string") {
						/**Recupera o status de aprovação e completudo da prova pelo candidato */
						const {
							teste_cultural: { situacao: status },
						}: { teste_cultural: { situacao: string } } = mindsight;

						/**Usuário fez a prova mas foi reprovado */
						if (status.toLowerCase() === "reprovado") {
							/**Caso o usuário não esteja aprovado, ele não está elegível a adquirir os produtos e, portanto, remove-os da lista de produtos sendo adquiridos */
							setTimeout(() => {
								addToast({
									type: "error",
									title: "Nota insuficiente",
									description:
										"Desculpe, você não atingiu a Nota Mínima no Teste para ingressar em nosso MBA. Para maiores esclarecimentos entre em contato com o nosso suporte",
									time: 10000,
								});

								setAcquiredProducts((prev) =>
									prev.filter(
										({ crypted_product_type_id }) =>
											crypted_product_type_id !== productTypeID,
									),
								);
							}, 1000);
						}
					} else {
						/**Caso o usuário não esteja aprovado, ele não está elegível a adquirir os produtos e, portanto, remove-os da lista de produtos sendo adquiridos */
						setTimeout(() => {
							/**Caso a resposta do mindsight seja apenas uma string, o perfil e/ou prova não foi encontrado(a) */
							addToast({
								type: "error",
								title: "Teste não realizado",
								description:
									"Desculpe, mas não identificamos nenhum teste realizado por este e-mail e/ou CPF para ingressar em nosso MBA. Para maiores esclarecimentos entre em contato com o nosso suporte",
								time: 10000,
							});

							setAcquiredProducts((prev) =>
								prev.filter(
									({ crypted_product_type_id }) =>
										crypted_product_type_id !== productTypeID,
								),
							);
						}, 1000);
					}
				} catch {
					addToast({
						type: "error",
						title: "Falha ao verificar nota(s) da(s) prova(s)",
						description:
							"Ocorreu um erro ao tentar verificar a(s) nota(s) da(s) prova(s) necessária(s)",
					});
				}
			}
		};

		personID && checkUserEligibility();
		// eslint-disable-next-line
	}, [productTypes, personID, setAcquiredProducts, addToast]);

	/**Efeito auxiliar que atualiza o produto atualmente selecionado caso a seleção já tenha vindo na URL */
	useEffect(() => {
		acquiredProducts.length > 0 &&
			setCurrentSelectedProduct(acquiredProducts[0]);
	}, [acquiredProducts]);

	/**Efeito que atualiza a variável que mantém o passo atual do usuário, quando o accordion for validado */
	useEffect(() => {
		setActiveStep(accordions!.findIndex(({ disabled }) => !disabled));
	}, [accordions, setActiveStep]);

	/**Efeito que verifica se o produto já foi passado via URL e, portanto, "completa" e desabilita o accordion */
	useEffect(() => {
		const product = new URL(window.location.href).searchParams.get(
			"igti_checkout_products",
		);

		acquiredProducts.length > 0 &&
			product &&
			setAccordions((prev) =>
				prev.map((acc) =>
					acc.id === id
						? {
								...acc,
								completed: true,
								completedInfo: {
									...acc.completedInfo,
									button: product.length > 0 ? "Alterar" : "",
									content: acquiredProducts.map(
										({ nome, nome_exibicao }) => `${nome_exibicao ?? nome}`,
									),
									extraContent: acquiredProducts[0].student_companies,
								},
							}
						: { ...acc },
				),
			);
	}, [id, acquiredProducts, setAccordions]);

	useEffect(() => {
		const product =
			new URL(window.location.href).searchParams.get(
				"igti_checkout_products",
			) ?? "";
		if (
			product.toLowerCase().includes("assinatura") ||
			product.toLowerCase().includes("bootcamp")
		) {
			setShouldShowTabs(false);
		}
	}, [products]);

	return (
		<CustomAccordion
			id={id}
			title="Seleção de Produto"
			disabled={disabled}
			expanded={expanded}
			setAccordions={setAccordions}
		>
			<div>
				<Container>
					<form onSubmit={handleSubmitAccordion}>
						{Object.keys(products).length > 0 && shouldShowTabs && (
							<Tabs
								className="tabsContainer"
								centered
								value={currentTab}
								onChange={(_, newValue) => setCurrentTab(newValue)}
							>
								{Object.keys(products).length > 0 &&
									Object.keys(products).map((type, index) => {
										return (
											type.toLowerCase() !== "multi+" && (
												<Tab
													key={index}
													label={type}
													sx={{ width: "100%", columnSpan: "all" }}
												/>
											)
										);
									})}
							</Tabs>
						)}
						<RadioGroup
							value={
								Object.keys(currentSelectedProduct).length > 0 &&
								currentSelectedProduct.crypted_id
							}
						>
							{Object.values(products).length > 0 &&
								Object.values(products)[currentTab].map((prod) => (
									<StyledFormControlLabel
										key={prod.crypted_id}
										checked={
											!!(currentSelectedProduct.crypted_id === prod.crypted_id)
										}
										onClick={() => {
											setCurrentSelectedProduct({
												...prod,
												tipo: Object.keys(products)[currentTab][0],
												valor_descontado:
													(prod.valor / 100) *
													(100 -
														productTypes.filter(
															({ crypted_id }) =>
																crypted_id === prod.crypted_product_type_id,
														)[0].early_discount),
											});

											setPaymentInfo((prev) => {
												return {
													...prev,
													coupon: "",
													couponValue: 0,
													discounts: [
														...(prev.discounts
															? [
																	...prev.discounts.filter(
																		({ name }) =>
																			name !== "Matrícula Antecipada" &&
																			name !== "Cupom de desconto",
																	),
																]
															: []),
														{
															name: "Matrícula Antecipada",
															value:
																(prod.valor / 100) *
																productTypes.filter(
																	({ crypted_id }) =>
																		crypted_id === prod.crypted_product_type_id,
																)[0].early_discount,
														},
													],
													generalist_voucher: false,
												};
											});
										}}
										label={prod.nome_exibicao ?? prod.nome}
										value={prod.crypted_id}
										control={<Radio />}
									/>
								))}
						</RadioGroup>
						{Object.values(currentSelectedProduct).length > 0 &&
						currentSelectedProduct.student_companies.length > 0 ? (
							<CompaniesDiv>
								<p>
									Alunos dessa e das últimas turmas trabalham nas seguintes
									empresas:
								</p>
								<div>
									{currentSelectedProduct.student_companies.map(
										({ image_link }, index) => (
											<img
												src={image_link}
												alt="Icone empresa"
												key={index}
											></img>
										),
									)}
								</div>
							</CompaniesDiv>
						) : (
							<></>
						)}

						{/* {Object.values(currentSelectedProduct).length > 0 &&
                  currentSelectedProduct.student_companies.length > 0 ? (
                     currentSelectedProduct.student_companies.map(({ image_link }) => (
                        <img src={image_link} alt="Icone empresa"></img>
                     ))
                  ) : (
                     <></>
                  )} */}
						<Button
							id="addressInfoSubmitButton"
							type="submit"
							variant="contained"
							color="success"
							endIcon={<ArrowForward />}
							onClick={() => setAcquiredProducts([currentSelectedProduct])}
							disabled={Object.keys(currentSelectedProduct).length < 1}
							sx={{ width: "100%", marginTop: "1rem" }}
						>
							{isLoading ? <CustomLoader /> : <span>Continuar</span>}
						</Button>
					</form>
				</Container>
			</div>
		</CustomAccordion>
	);
};

export default ItemInfoAccordion;
